import { Injectable } from '@angular/core';

@Injectable()
export class ReplacerService {

  transl = {
    'А': 'A', 'а': 'a', 'Б': 'B', 'б': 'b', 'В': 'V', 'в': 'v', 'Г': 'G', 'г': 'g', 'Д': 'D',
    'д': 'd', 'Е': 'E', 'е': 'e', 'Ё': 'E', 'ё': 'e', 'Ж': 'Zh',
    'ж': 'zh', 'З': 'Z', 'з': 'z', 'И': 'I', 'и': 'i', 'Й': 'Y', 'й': 'y', 'К': 'K', 'к': 'k', 'Л': 'L', 'л': 'l', 'М': 'M',
    'м': 'm', 'Н': 'N', 'н': 'n', 'О': 'O', 'о': 'o', 'П': 'P', 'п': 'p', 'Р': 'R', 'р': 'r', 'С': 'S', 'с': 's',
    'Т': 'T', 'т': 't', 'У': 'U', 'у': 'u', 'Ф': 'F', 'ф': 'f', 'Х': 'h', 'х': 'h', 'Ц': 'c', 'ц': 'c', 'Ч': 'Ch', 'ч': 'ch',
    'Ш': 'Sh', 'ш': 'sh', 'Щ': 'Sch', 'щ': 'sch', 'ь': '', 'Ы': 'i', 'ы': 'i', 'ъ': '', 'Э': 'E', 'э': 'e', 'Ю': 'Yu', 'ю': 'yu', 'Я': 'Ya',
    'я': 'ya', ' ': '-', '/': ''
  };

  translate = (str) => this.functionTranslate(this.transl)(str);

  functionTranslate(map) {
    const keys = Object.keys(map).sort((a, b) => b.length - a.length);
    const peek = (str) => {
      for (let i = 0; i < keys.length; i++)
        if (this.startsWith(keys[i], str)) {
          return keys[i];
        }
    };
    return function (str) {
      let out = '';
      while (str) {
        const key = peek(str);
        if (key) {
          out += map[key];
          str = str.slice(key.length);
        } else {
          out += str[0];
          str = str.slice(1);
        }
      }
      return out;
    };
  }

  startsWith(start, str) {
    for (let i = 0; i < start.length; i++)
      if (start[i] != str[i]) {
        return false;
      }
    return true;
  }

  number(number: number | string = 0): string {
    return Math.round(+number).toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
  }

  price(price: number | string = 0, dote: boolean = true): string {
    return price == 0 || price == null ? 'По запросу' : this.number(price) + ' ₽' + (dote ? '.' : '');
  }

  phone(phone: string = ''): string {
    return phone.replace(/\D/g, '');
  }

  capitalize(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  numberWord(count: string | number, word: string[]): string {
    return count == 0 ? word[2] : 0 == +count % 10 || +count % 100 > 10 && 20 > +count % 100 || +count % 10 > 4 ? word[2] : 1 == +count % 10 ? word[0] : word[1];
  }

  lowercase = string => string.toLowerCase();
  firstPartItems = ({array, onPageCount, activePage}) => array.slice(onPageCount * (activePage - 1), onPageCount * (activePage - 1) + onPageCount / 3);
  secondPartItems = ({array, onPageCount, activePage}) => array.slice(onPageCount * (activePage - 1) + onPageCount / 3, onPageCount * (activePage - 1) + (onPageCount / 3) * 2);
  thirdPartItems = ({array, onPageCount, activePage}) => array.slice(onPageCount * (activePage - 1) + (onPageCount / 3) * 2, onPageCount * (activePage - 1) + (onPageCount / 3) * 3);
  repeatObjectProperty = (object, property, count) => count == 0 ? object : this.repeatObjectProperty(object[property], property, --count);
}
