import {Injectable, ChangeDetectorRef} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CookieService} from './cookie.service';
import CONFIG from '../../../../CONFIG';
import {Subject} from 'rxjs/internal/Subject';

@Injectable()
export class MainHeaderService {
  _title = '';
  user: any = {};
  avatarTime = 0;
  URL: string = CONFIG.url;
  DATA_URL: string = CONFIG.url + '/data/client/';
  IMAGE_URL: string = CONFIG.url + '/assets/img/';
  observerTitle = new Subject();

  constructor(public http: HttpClient, private CS: CookieService) {
  }

  set title(val) {
    this._title = val;
    this.observerTitle.next();
  }

  get title() {
    return this._title;
  }


  get(url: string, params = {}): Promise<any> {
    return this.http.get(this.DATA_URL + url, {params}).toPromise();
  }

  post(url: string, object: any): Promise<any> {
    return this.http.post(this.DATA_URL + url, object).toPromise();
  }

  put() {

  }

  saveUser(object) {
    return this.http.put(this.DATA_URL + 'user', object).toPromise();
  }

  setLogs(_test) {
    let test = JSON.parse(JSON.stringify(_test));
    return this.http.post(this.DATA_URL + 'statistic/test/' + test._id, this.getBody(test)).toPromise();
  }

  getBody = test => ({
    content: [{
      questions: test.questions.filter(question => question.answer !== undefined).map((question: any) => {
        return {
          isTrue: question.trueQuestion == question.answer,
          answer: question.variants[question.answer].index
          //name: question.variants[question.answer].name
        };
      })
    }],
    name: test.name,
    test: test._id,
    user: this.user._id
  });

}
