import {Injectable} from '@angular/core';

@Injectable()
export class CookieService {

  cookieTime: number = 60 * 60 * 24 * 60;
  constructor() {}

  getCookie(name: string): string {
    const matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  getCookieObject = (name: string): any => this.getCookie(name) != undefined ? JSON.parse(this.getCookie(name)) : {};

  setCookie(name, value, options: any = {}) {
    let expires = options.expires || this.cookieTime;
    if (typeof expires == "number" && expires) {
      const d = new Date();
      d.setTime(d.getTime() + expires * 1000);
      expires = options.expires = d;
    }
    if (expires && expires.toUTCString) {
      options.expires = expires.toUTCString();
    }
    value = encodeURIComponent(value);
    let updatedCookie = name + "=" + value;
    for (const propName of Object.keys(options)) {
      updatedCookie += "; " + propName;
      const propValue = options[propName];
      if (propValue !== true) {
        updatedCookie += "=" + propValue;
      }
    }
    document.cookie = updatedCookie;
  }

  setCookieObject(name, value, options = {}) {
    this.setCookie(name, JSON.stringify(value), options);
  }

  deleteCookie(name) {
    this.setCookie(name, "", {expires: -1})
  }
}
