import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {MainHeaderService} from './services/main-header.service';
import {CookieService} from './services/cookie.service';

@Injectable()
export class LoginGuard implements CanActivate {
  constructor(private CS: CookieService, private router: Router, private MHS: MainHeaderService) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.CS.getCookieObject('login_getapass')) {
      try {
        const user = await this.MHS.get('user', {email: this.CS.getCookieObject('login_getapass')['email']});
        this.MHS.user = user;
        return true;
      } catch (error) {
        this.router.navigateByUrl('login/sign-in');
        return false;
      }
    } else {
      this.router.navigate(['login/sign-in']);
      return false;
    }
  }
}

@Injectable()
export class LogoutGuard implements CanActivate {
  constructor(private router: Router, private CS: CookieService) {}

  canActivate() {
    this.CS.deleteCookie('login_getapass');
    this.router.navigate(['login']);
    return false;
  }
}
